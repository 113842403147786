import LinkComponent from '../LinkComponent/LinkComponent'
import './AuthFooter.scss'

const AuthFooter = () => {
  return (
    <div className="AuthFooter">
      <div className="AuthFooter__links">
        <LinkComponent className="AuthFooter__link" href="/terms">
          <span className="AuthFooter__link--text">Terms of service</span>
          <i className="AuthFooter__link--icon bx bx-link-external"></i>
        </LinkComponent>
        <div className="AuthFooter__linkSeparator"></div>
        <LinkComponent className="AuthFooter__link" href="/privacy">
          <span className="AuthFooter__link--text">Privacy policy</span>
          <i className="AuthFooter__link--icon bx bx-link-external"></i>
        </LinkComponent>
      </div>
      <div className="AuthFooter__copyright">
        &copy; Copyright {new Date().getFullYear()} | Xanadu | All rights
        reserved
      </div>
    </div>
  )
}

export default AuthFooter
